<template>
  <div class="bg-white heading-font-family fs-16 headings-letter-spacing-normal">
    <section>
      <div class="landing-header container">
        <nav class="navbar navbar-expand-md bg-transparent">
          <router-link to="/" class="navbar-brand justify-content-start"><p>agiler</p></router-link>
          <div class="collapse navbar-collapse">
            <ul class="navbar-nav ml-auto">
              <li class="nav-item"><router-link class="nav-link" to="/contact"><i class="fal fa-envelope mr-2" aria-hidden="true"></i>hello@agiler.io</router-link></li>
              <li class="nav-item"><a class="nav-link" href="tel:1-800-249-1897"><i class="fal fa-phone mr-2" aria-hidden="true"></i>(800) 249-1897</a></li>
            </ul>
          </div>
          <ul class="navbar-nav">
            <li class="nav-item d-md-none"><router-link class="nav-link" to="/contact"><i class="fal fa-envelope mr-2" aria-hidden="true"></i></router-link></li>
            <li class="nav-item d-md-none"><a class="nav-link" href="tel:1-800-249-1897"><i class="fal fa-phone mr-2" aria-hidden="true"></i></a></li>
            <li class="nav-item"><router-link to="/login" class="btn btn-outline-light btn-rounded btn-sm px-4 py-2 ml-2">{{ loginButtonText }}<i class="fal fa-sign-in ml-2"></i></router-link></li>
          </ul>
        </nav>
      </div>

      <div class="bg-dark-blue container-fluid pos-relative d-flex flex-column text-inverse" style="height: 80vh; min-height: 670px;">
        <div class="row flex-1">
          <div id="particles-background" class="pos-0"></div>
          <div class="waves-background pos-0 no-pointer-events"></div>
          <div class="container d-flex flex-column">
            <div class="row flex-1 align-items-center">
              <div class="col-lg-10 offset-lg-1 text-center">
                <h1 class="d-none d-lg-block mb-0 fw-500 fs-54" style="margin-top: -8%;">Ecommerce <br><span class="text-warning">Essentials + Optimization</span></h1>
                <h1 class="d-block d-lg-none my-0 fw-500 fs-48">Ecommerce <br><span class="text-warning">Essentials + Optimization</span></h1>
                <h6 class="px-3 px-sm-0 my-4 pb-2 fs-16 font-weight-normal lh-24">
                  Agiler is a <strong class="fw-600">proactive data-driven design &amp; engineering service provider</strong> focused on improving customer experiences to reduce CPA and increase LTV by building effective enjoyable ecommerce stores.
                </h6>
                <ul class="list-unstyled list-inline fs-15">
                  <li class="list-inline-item"><i class="fab fa-shopify fa-lg mr-2"></i>Shopify</li>
                  <li class="list-inline-item"><i class="fab fa-wordpress fa-lg ml-4 ml-md-5 mr-2"></i>WooCommerce</li>
                  <li class="list-inline-item d-none d-md-inline-block"><i class="fal fa-flag-usa fa-lg ml-4 ml-md-5 mr-2"></i>USA Based</li>
                </ul>
                <router-link to="/signup?mode=company" class="btn btn-lg btn-light btn-rounded px-5 mt-4 ripple">Sign Up</router-link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="container-fluid px-3" style="padding-top: 60px; padding-bottom: 60px;">
      <div class="container">
        <div class="section-title">
          <h2 class="heading">Optimal Ecommerce <span class="text-warning">Essentials</span></h2>
          <h6 class="subheading">Proactive support required to build a successful online business</h6>
        </div>
        <div class="row planfeature bg-light">
          <div class="col planfeature-description rotate d-none d-md-block"><div class="rotate-center text-center">Help</div></div>
          <div class="col-sm-4 col-md-3 col-xl-2 text-center">
            <span class="fa-stack fa-5x text-stroke-2 mb-4 mb-sm-0">
              <i class="fal fa-hand-holding-box fa-stack-1x text-warning small"></i>
              <i class="fal fa-circle-notch text-stroke-8 fa-stack-2x text-warning fa-rotate-120"></i>
            </span>
          </div>
          <div class="col-sm-7 col-xl-8">
            <div class="d-table h-100">
              <div class="d-table-cell vertical-middle">
                <h4 class="title mt-0 ml-sm-1">24/7/365 Store Support</h4>
                <p class="description mb-2 ml-sm-1">
                  Your website is a critical asset of your ecommerce business. Our staff will resolve any technical issues with your store; update your product catalog; design pages and graphics; and other day to day tasks. Emergency support is available 24 hours a day even on weekends and holidays. The internet never closes and neither do we.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div class="row planfeature">
          <div class="col planfeature-description rotate d-none d-md-block"><div class="rotate-center text-center">Marketing</div></div>
          <div class="col-sm-4 col-md-3 col-xl-2 text-center">
            <span class="fa-stack fa-5x text-stroke-2 mb-4 mb-sm-0">
              <i class="fal fa-bullseye-pointer fa-stack-1x text-warning small"></i>
              <i class="fal fa-circle-notch text-stroke-8 fa-stack-2x text-warning fa-rotate-120"></i>
            </span>
          </div>
          <div class="col-sm-7 col-xl-8">
            <div class="d-table h-100">
              <div class="d-table-cell vertical-middle">
                <h4 class="title mt-0 ml-sm-1">Analytics &amp; Marketing Support</h4>
                <p class="description mb-2 ml-sm-1">
                  Efficient marketing is essential to surviving in highly competitive markets. We will integrate ecommerce analytics, conversion tracking, and other tools required to track the results of your marketing campaigns.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div class="row planfeature bg-light">
          <div class="col planfeature-description rotate d-none d-md-block"><div class="rotate-center text-center">SEO</div></div>
          <div class="col-sm-4 col-md-3 col-xl-2 text-center">
            <span class="fa-stack fa-5x text-stroke-2 mb-4 mb-sm-0">
              <i class="fal fa-broadcast-tower fa-stack-1x text-warning small"></i>
              <i class="fal fa-circle-notch text-stroke-8 fa-stack-2x text-warning fa-rotate-120"></i>
            </span>
          </div>
          <div class="col-sm-7 col-xl-8">
            <div class="d-table h-100">
              <div class="d-table-cell vertical-middle">
                <h4 class="title mt-0 ml-sm-1">Product Search Engine Optimization (SEO)</h4>
                <p class="description mb-2 ml-sm-1">
                  Product SEO uses SEO to drive sales. It focuses on putting your products where customers are searching and comparing similar items; optimizing your product pages for search engines; enabling SERP features to highlight your business; and other tactics to make your store more visible.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div class="row planfeature">
          <div class="col planfeature-description rotate d-none d-md-block"><div class="rotate-center text-center">Speed</div></div>
          <div class="col-sm-4 col-md-3 col-xl-2 text-center">
            <span class="fa-stack fa-5x text-stroke-2 mb-4 mb-sm-0">
              <i class="fal fa-rocket-launch fa-stack-1x text-warning small"></i>
              <i class="fal fa-circle-notch text-stroke-8 fa-stack-2x text-warning fa-rotate-120"></i>
            </span>
          </div>
          <div class="col-sm-7 col-xl-8">
            <div class="d-table h-100">
              <div class="d-table-cell vertical-middle">
                <h4 class="title mt-0 ml-sm-1">Performance Tuning</h4>
                <p class="description mb-2 ml-sm-1">
                  Page load time can change a delightful experience into a frustrating ordeal. It slows the ordering process, makes it more difficult for customers to find information on your website, and has a direct effect on the conversion rate. We'll tune your website to load quickly, including optimizing images and rewriting template code to perform better.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div class="row planfeature bg-light">
          <div class="col planfeature-description rotate d-none d-md-block"><div class="rotate-center text-center">Update</div></div>
          <div class="col-sm-4 col-md-3 col-xl-2 text-center">
            <span class="fa-stack fa-5x text-stroke-2 mb-4 mb-sm-0">
              <i class="fal fa-cogs fa-stack-1x text-warning small"></i>
              <i class="fal fa-circle-notch text-stroke-8 fa-stack-2x text-warning fa-rotate-120"></i>
            </span>
          </div>
          <div class="col-sm-7 col-xl-8">
            <div class="d-table h-100">
              <div class="d-table-cell vertical-middle">
                <h4 class="title mt-0 ml-sm-1">Store Updates &amp; Bug Fixes</h4>
                <p class="description mb-2 ml-sm-1">
                  Technical and design bugs negatively impact store metrics. Unfortunately the web is constantly changing as browsers are updated. Each of those updates risks introducing bugs to your store. We proactively monitor and test your store to catch and fix new bugs. We'll also keep your theme and other store software up to date.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div class="row planfeature">
          <div class="col planfeature-description rotate d-none d-md-block"><div class="rotate-center text-center">Plugin</div></div>
          <div class="col-sm-4 col-md-3 col-xl-2 text-center">
            <span class="fa-stack fa-5x text-stroke-2 mb-4 mb-sm-0">
              <i class="fal fa-puzzle-piece fa-stack-1x text-warning small" style="margin-left: 0.33rem; margin-top: -0.33rem;"></i>
              <i class="fal fa-circle-notch text-stroke-8 fa-stack-2x text-warning fa-rotate-120"></i>
            </span>
          </div>
          <div class="col-sm-7 col-xl-8">
            <div class="d-table h-100">
              <div class="d-table-cell vertical-middle">
                <h4 class="title mt-0 ml-sm-1">Plugin Setup &amp; Maintenance</h4>
                <p class="description mb-2 ml-sm-1">
                  Adding new functionality to your store requires more than clicking install on a plugin. We'll setup new plugins with your store so they seemlessly integrate with your existing theme. We'll also evaluate plugin security to keep your customers safe; and update and maintain your plugins so they don't break your store or cause other issues.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div class="row planfeature bg-light">
          <div class="col planfeature-description rotate d-none d-md-block"><div class="rotate-center text-center">Safety</div></div>
          <div class="col-sm-4 col-md-3 col-xl-2 text-center">
            <span class="fa-stack fa-5x text-stroke-2 mb-4 mb-sm-0">
              <i class="fal fa-meteor fa-stack-1x text-warning small"></i>
              <i class="fal fa-circle-notch text-stroke-8 fa-stack-2x text-warning fa-rotate-120"></i>
            </span>
          </div>
          <div class="col-sm-7 col-xl-8">
            <div class="d-table h-100">
              <div class="d-table-cell vertical-middle">
                <h4 class="title mt-0 ml-sm-1">Backups &amp; Disaster Recovery</h4>
                <p class="description mb-2 ml-sm-1">
                  Forgotten until the unexpected happens, backups are indispensable. Our platform will automatically backup your store regularly, including all of it's product and order data. When disaster strikes, our emergency support staff are available 24 hours a day, 365 days a year to restore and fix your store.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="container-fluid bg-dark-blue pos-relative d-flex flex-column text-inverse py-5">
      <div class="container py-3">
        <div class="row flex-1">
          <div id="particles-signup-background" class="pos-0"></div>
          <div class="container d-flex flex-column">
            <div class="row flex-1 align-items-center d-sm-none">
              <div class="col-sm-8 text-center">
                <h1 class="fw-500 fs-24 my-0">Foundation for <span class="text-warning">Ecommerce Success</span></h1>
              </div>
              <div class="col-sm-4 text-center">
                <router-link to="/signup?mode=company" class="btn btn-lg btn-light btn-rounded px-5 mt-3 ripple">Sign Up</router-link>
              </div>
            </div>
            <div class="row flex-1 align-items-center d-none d-sm-flex">
              <div class="col-sm-8">
                <h1 class="fw-500 fs-28 my-0 d-none d-lg-block">Foundation for <span class="text-warning">Ecommerce Success</span></h1>
                <h1 class="fw-500 fs-24 my-0 d-lg-none">Foundation for <span class="text-warning">Ecommerce Success</span></h1>
              </div>
              <div class="col-sm-4 text-right">
                <router-link to="/signup?mode=company" class="btn btn-lg btn-light btn-rounded px-5 ripple">Sign Up</router-link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="container-fluid px-3" style="padding-top: 60px; padding-bottom: 60px;">
      <div class="container">
        <div class="section-title">
          <h2 class="heading">CRO &amp; LTV <span class="text-warning">Optimization</span></h2>
          <h6 class="subheading">Proactive data-driven optimization to deliver non-disruptive incremental growth</h6>
        </div>
        <div class="row planfeature bg-light">
          <div class="col planfeature-description rotate d-none d-md-block"><div class="rotate-center text-center">Analysis</div></div>
          <div class="col-sm-4 col-md-3 col-xl-2 text-center">
            <span class="fa-stack fa-5x text-stroke-2 mb-4 mb-sm-0">
              <i class="fal fa-chart-scatter fa-stack-1x text-warning small"></i>
              <i class="fal fa-circle-notch text-stroke-8 fa-stack-2x text-warning fa-rotate-120"></i>
            </span>
          </div>
          <div class="col-sm-7 col-xl-8">
            <div class="d-table h-100">
              <div class="d-table-cell vertical-middle">
                <h4 class="title mt-0 ml-sm-1">Analytics Data Analysis</h4>
                <p class="description mb-2 ml-sm-1">
                  Data is meaningless without context. Our senior analysts have the experience to understand what data should be collected and how to analyze that data to provide insight into your customers.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div class="row planfeature">
          <div class="col planfeature-description rotate d-none d-md-block"><div class="rotate-center text-center">Design</div></div>
          <div class="col-sm-4 col-md-3 col-xl-2 text-center">
            <span class="fa-stack fa-5x text-stroke-2 mb-4 mb-sm-0">
              <i class="fal fa-user-chart fa-stack-1x text-warning small"></i>
              <i class="fal fa-circle-notch text-stroke-8 fa-stack-2x text-warning fa-rotate-120"></i>
            </span>
          </div>
          <div class="col-sm-7 col-xl-8">
            <div class="d-table h-100">
              <div class="d-table-cell vertical-middle">
                <h4 class="title mt-0 ml-sm-1">Test Design</h4>
                <p class="description mb-2 ml-sm-1">
                  In Conversion Rate Optimization (CRO) a well designed test not only improves the metric being tested but can also provide insight into your customers to drive future successful tests. Our analysts, developers, and designers work together to create well designed tests.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div class="row planfeature bg-light">
          <div class="col planfeature-description rotate d-none d-md-block"><div class="rotate-center text-center">Test</div></div>
          <div class="col-sm-4 col-md-3 col-xl-2 text-center">
            <span class="fa-stack fa-5x text-stroke-2 mb-4 mb-sm-0">
              <i class="fal fa-rabbit-fast fa-stack-1x text-warning small"></i>
              <i class="fal fa-circle-notch text-stroke-8 fa-stack-2x text-warning fa-rotate-120"></i>
            </span>
          </div>
          <div class="col-sm-7 col-xl-8">
            <div class="d-table h-100">
              <div class="d-table-cell vertical-middle">
                <h4 class="title mt-0 ml-sm-1">Rapid Testing</h4>
                <p class="description mb-2 ml-sm-1">
                  A thorough understanding of statistics allows us to run more independent tests concurrently. We can also optimize at multiple points in the conversion process and/or using non-converting actions to complete tests faster with lower sales volume.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div class="row planfeature">
          <div class="col planfeature-description rotate d-none d-md-block"><div class="rotate-center text-center">Risk</div></div>
          <div class="col-sm-4 col-md-3 col-xl-2 text-center">
            <span class="fa-stack fa-5x text-stroke-2 mb-4 mb-sm-0">
              <i class="fal fa-umbrella fa-stack-1x text-warning small"></i>
              <i class="fal fa-circle-notch text-stroke-8 fa-stack-2x text-warning fa-rotate-120"></i>
            </span>
          </div>
          <div class="col-sm-7 col-xl-8">
            <div class="d-table h-100">
              <div class="d-table-cell vertical-middle">
                <h4 class="title mt-0 ml-sm-1">Risk Mitigation</h4>
                <p class="description mb-2 ml-sm-1">
                  Optimization uses analytics and split or multivariate testing to increase sales with minimal risk. The testing process allows us to limit the number of customers in a test and ensure only successful tests are permanently incorporated into the store.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div class="row planfeature bg-light">
          <div class="col planfeature-description rotate d-none d-md-block"><div class="rotate-center text-center">LTV</div></div>
          <div class="col-sm-4 col-md-3 col-xl-2 text-center">
            <span class="fa-stack fa-5x text-stroke-2 mb-4 mb-sm-0">
              <i class="fal fa-repeat fa-stack-1x text-warning small"></i>
              <i class="fal fa-circle-notch text-stroke-8 fa-stack-2x text-warning fa-rotate-120"></i>
            </span>
          </div>
          <div class="col-sm-7 col-xl-8">
            <div class="d-table h-100">
              <div class="d-table-cell vertical-middle">
                <h4 class="title mt-0 ml-sm-1">Customer Life Cycle Analysis</h4>
                <p class="description mb-2 ml-sm-1">
                  Our analysts can collect data on each interaction a customer has with your store including mailing lists, order data, and support systems. We'll analyze this data to provide insights into your customers and drive future tests to increase customer life time value.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="container-fluid px-3 bg-dark-blue pos-relative d-flex flex-column py-5">
      <div class="container">
        <div class="row flex-1">
          <div id="particles-pricing-background" class="pos-0"></div>
          <div class="container d-flex flex-column">
            <div class="row flex-1 align-items-center py-5 my-5">
              <div class="col-lg-12 px-0 text-center py-4 my-4">

                <div class="section-title particles-section-title text-inverse mb-4 pb-1">
                  <h1 class="heading fw-500 fs-46">Simple <span class="text-warning">Pricing</span></h1>
                  <h6 class="subheading">Flat rate pricing with no surprises</h6>
                </div>

                <div class="widget-list">
                  <div class="row">
                    <div class="col-md-12 widget-holder no-drop-shadow">
                      <div class="widget-bg-transparent">
                        <div class="widget-body p-1 clearfix">
                          <div class="row m-0">

                            <div class="col-12 col-lg-4">
                              <div class="pricing-box">
                                <header class="text-center">
                                  <h5 class="text-primary fw-400 sub-heading-font-family mb-4">Essentials</h5>
                                  <span class="h5 heading-basic bg-primary color-white rounded-circle sub-heading-font-family"><span class="d-block" style="margin-top: -7px;">$3900</span></span>
                                  <span class="color-white block mb-5 pb-2 fs-13 fw-500" style="margin-top: -54px;">monthly</span>
                                </header>
                                <hr>
                                <div class="pricing-box-content px-5">
                                  <h6 class="text-center pt-2">Proactive Support</h6>
                                  <ul class="list-unstyled mt-4">
                                    <li class="d-flex">
                                      <span class="text-left fa-stack">
                                        <i class="fal fa-check fa-stack-1x text-warning small"></i>
                                        <i class="fal fa-circle-notch text-stroke-1 fa-stack-2x text-warning fa-rotate-120"></i>
                                      </span>
                                      <span class="description d-inline-block m-auto">24/7/365 Store Support</span>
                                    </li>
                                    <li class="d-flex">
                                      <span class="text-left fa-stack">
                                        <i class="fal fa-check fa-stack-1x text-warning small"></i>
                                        <i class="fal fa-circle-notch text-stroke-1 fa-stack-2x text-warning fa-rotate-120"></i>
                                      </span>
                                      <span class="description d-inline-block m-auto">Analytics &amp; Marketing Support</span>
                                    </li>
                                    <li class="d-flex">
                                      <span class="text-left fa-stack">
                                        <i class="fal fa-check fa-stack-1x text-warning small"></i>
                                        <i class="fal fa-circle-notch text-stroke-1 fa-stack-2x text-warning fa-rotate-120"></i>
                                      </span>
                                      <span class="description d-inline-block m-auto">Search Engine Optimization (SEO)</span>
                                    </li>
                                    <li class="d-flex">
                                      <span class="text-left fa-stack">
                                        <i class="fal fa-check fa-stack-1x text-warning small"></i>
                                        <i class="fal fa-circle-notch text-stroke-1 fa-stack-2x text-warning fa-rotate-120"></i>
                                      </span>
                                      <span class="description d-inline-block m-auto">Performance Tuning</span>
                                    </li>
                                    <li class="d-flex">
                                      <span class="fa-stack">
                                        <i class="fal fa-check fa-stack-1x text-warning small"></i>
                                        <i class="fal fa-circle-notch text-stroke-1 fa-stack-2x text-warning fa-rotate-120"></i>
                                      </span>
                                      <span class="description d-inline-block m-auto">Store Updates &amp; Bug Fixes</span>
                                    </li>
                                    <li class="d-flex">
                                      <span class="text-left fa-stack">
                                        <i class="fal fa-check fa-stack-1x text-warning small"></i>
                                        <i class="fal fa-circle-notch text-stroke-1 fa-stack-2x text-warning fa-rotate-120"></i>
                                      </span>
                                      <span class="description d-inline-block m-auto">Plugin Setup &amp; Maintenance</span>
                                    </li>
                                    <li class="d-flex">
                                      <span class="text-left fa-stack">
                                        <i class="fal fa-check fa-stack-1x text-warning small"></i>
                                        <i class="fal fa-circle-notch text-stroke-1 fa-stack-2x text-warning fa-rotate-120"></i>
                                      </span>
                                      <span class="description d-inline-block m-auto">Backups &amp; Disaster Recovery</span>
                                    </li>
                                  </ul>
                                  <div class="text-center"><router-link to="/signup?mode=company&plan=essentials" class="btn btn-lg btn-outline-primary btn-rounded py-3 mt-2">Sign Up</router-link></div>
                                </div>
                              </div>
                            </div>

                            <div class="col-12 col-lg-4">
                              <div class="pricing-box featured-pricing-box">
                                <header class="text-center">
                                  <h5 class="text-primary fw-400 sub-heading-font-family mb-4">Essentials + Optimization</h5>
                                  <span class="h4 heading-basic bg-primary color-white rounded-circle sub-heading-font-family"><span class="d-block" style="margin-top: -8px;">$5900</span></span>
                                  <span class="color-white block mb-5 pb-4 fs-15 fw-500" style="margin-top: -66px;">monthly</span>
                                  <div class="triangle-top-right"></div>
                                </header>
                                <hr>
                                <div class="pricing-box-content px-5">
                                  <h6 class="text-center pt-3">Proactive Growth</h6>
                                  <ul class="list-unstyled mt-4">
                                    <li class="d-flex">
                                      <span class="text-left fa-stack">
                                        <i class="fal fa-check fa-stack-1x text-warning small"></i>
                                        <i class="fal fa-circle-notch text-stroke-1 fa-stack-2x text-warning fa-rotate-120"></i>
                                      </span>
                                      <span class="description d-inline-block m-auto">Analytics Data Analysis</span>
                                    </li>
                                    <li class="d-flex">
                                      <span class="text-left fa-stack">
                                        <i class="fal fa-check fa-stack-1x text-warning small"></i>
                                        <i class="fal fa-circle-notch text-stroke-1 fa-stack-2x text-warning fa-rotate-120"></i>
                                      </span>
                                      <span class="description d-inline-block m-auto">Test Design</span>
                                    </li>
                                    <li class="d-flex">
                                      <span class="text-left fa-stack">
                                        <i class="fal fa-check fa-stack-1x text-warning small"></i>
                                        <i class="fal fa-circle-notch text-stroke-1 fa-stack-2x text-warning fa-rotate-120"></i>
                                      </span>
                                      <span class="description d-inline-block m-auto">Test Implementation &amp; Monitoring</span>
                                    </li>
                                    <li class="d-flex">
                                      <span class="text-left fa-stack">
                                        <i class="fal fa-check fa-stack-1x text-warning small"></i>
                                        <i class="fal fa-circle-notch text-stroke-1 fa-stack-2x text-warning fa-rotate-120"></i>
                                      </span>
                                      <span class="description d-inline-block m-auto">Rapid Testing</span>
                                    </li>
                                    <li class="d-flex">
                                      <span class="text-left fa-stack">
                                        <i class="fal fa-check fa-stack-1x text-warning small"></i>
                                        <i class="fal fa-circle-notch text-stroke-1 fa-stack-2x text-warning fa-rotate-120"></i>
                                      </span>
                                      <span class="description d-inline-block m-auto">Customer Life Cycle Analysis</span>
                                    </li>
                                    <li class="d-flex">
                                      <span class="text-left fa-stack">
                                        <i class="fal fa-check fa-stack-1x text-warning small"></i>
                                        <i class="fal fa-circle-notch text-stroke-1 fa-stack-2x text-warning fa-rotate-120"></i>
                                      </span>
                                      <span class="description d-inline-block m-auto">Email &amp; Mailing List Optimization</span>
                                    </li>
                                  </ul>
                                  <h6 class="pt-2 pb-3 text-center">Essentials Included<br></h6>
                                  <div class="text-center"><router-link to="/signup?mode=company&plan=optimization" class="btn btn-lg btn-primary btn-rounded py-3 mt-2">Sign Up</router-link></div>
                                </div>
                              </div>
                            </div>

                            <div class="col-12 col-lg-4" style="margin-top: 3em;">
                              <div class="pricing-box">
                                <header class="text-center">
                                  <h5 class="text-dark fw-400 sub-heading-font-family mb-4">Enterprise</h5>
                                  <span class="h5 heading-basic bg-dark color-white rounded-circle sub-heading-font-family"><span class="d-block" style="margin-top: -7px;">Custom</span></span>
                                  <span class="color-white block mb-5 pb-2 fs-13 fw-500" style="margin-top: -54px;">pricing</span>
                                </header>
                                <hr>
                                <div class="pricing-box-content px-5">
                                  <h6 class="text-center pt-2">Team-Based Solutions</h6>
                                  <ul class="list-unstyled mt-4">
                                    <li class="d-flex">
                                      <span class="text-left fa-stack">
                                        <i class="fal fa-check fa-stack-1x text-warning small"></i>
                                        <i class="fal fa-circle-notch text-stroke-1 fa-stack-2x text-warning fa-rotate-120"></i>
                                      </span>
                                      <span class="description d-inline-block m-auto">Dedicated Team</span>
                                    </li>
                                    <li class="d-flex">
                                      <span class="text-left fa-stack">
                                        <i class="fal fa-check fa-stack-1x text-warning small"></i>
                                        <i class="fal fa-circle-notch text-stroke-1 fa-stack-2x text-warning fa-rotate-120"></i>
                                      </span>
                                      <span class="description d-inline-block m-auto">Custom Ecommerce Platform Development</span>
                                    </li>
                                    <li class="d-flex">
                                      <span class="text-left fa-stack">
                                        <i class="fal fa-check fa-stack-1x text-warning small"></i>
                                        <i class="fal fa-circle-notch text-stroke-1 fa-stack-2x text-warning fa-rotate-120"></i>
                                      </span>
                                      <span class="description d-inline-block m-auto">Custom Software, Apps &amp; Themes</span>
                                    </li>
                                    <li class="d-flex">
                                      <span class="text-left fa-stack">
                                        <i class="fal fa-check fa-stack-1x text-warning small"></i>
                                        <i class="fal fa-circle-notch text-stroke-1 fa-stack-2x text-warning fa-rotate-120"></i>
                                      </span>
                                      <span class="description d-inline-block m-auto">Warehouse, ERP &amp; Other Integrations</span>
                                    </li>
                                    <li class="d-flex">
                                      <span class="fa-stack">
                                        <i class="fal fa-check fa-stack-1x text-warning small"></i>
                                        <i class="fal fa-circle-notch text-stroke-1 fa-stack-2x text-warning fa-rotate-120"></i>
                                      </span>
                                      <span class="description d-inline-block m-auto">Staff Available On Site</span>
                                    </li>
                                  </ul>
                                  <div class="text-center"><router-link to="/contact" class="btn btn-lg btn-outline-primary btn-rounded py-3 mt-2">Contact Us</router-link></div>
                                </div>
                              </div>
                            </div>

                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="container-fluid px-3" style="padding-top: 60px; padding-bottom: 60px;">
      <div class="container">
        <div class="section-title">
          <h2 class="heading">Frequently Asked <span class="text-warning">Questions</span></h2>
          <h6 class="subheading">About our services and how we work for you</h6>
        </div>
        <div class="row">
          <div class="col-lg-6 pr-lg-5 pb-5">
            <h6 class="mt-0">What platforms do you support?</h6>
            We currently work with Shopify, Shopify Plus, and WooCommerce. For other platforms, please <router-link to="/contact">contact us</router-link> to ensure our service and tools are compatible with your platform.
          </div>
          <div class="col-lg-6 pr-lg-5 pb-5">
            <h6 class="mt-0">Can I add people in my company to the project dashboard?</h6>
            Yes. You can add as many people as needed to your project. Each person will be able to provide input into Agiler's work.
          </div>
          <div class="col-lg-6 pr-lg-5 pb-5">
            <h6 class="mt-0">Can I see the results of the optimization tests you run?</h6>
            Yes. For each test we'll document the test, our thoughts about why we're performing the test, and the result we're expecting. Once the test is completed, we'll provide full statistical results and an explanation of those results.
          </div>
          <div class="col-lg-6 pr-lg-5 pb-5">
            <h6 class="mt-0">How much input will I have into your work?</h6>
            We provide options, advice, and technical ability. The direction you take your store is your decision. We fully document our work in the project dashboard and you may provide input into the work at any time. We give you full access to the project dashboard so that you can participate and track our work.
          </div>
          <div class="col-lg-6 pr-lg-5 pb-5">
            <h6 class="mt-0">Can you focus on a specific problem area?</h6>
            Yes. Your assigned project manager is responsible for planning the work on your store. They can schedule more or less work on a particular area as needed.
          </div>
          <div class="col-lg-6 pr-lg-5 pb-5">
            <h6 class="mt-0">Do I have to sign a long term contract?</h6>
            No long term contract is required. You can cancel at any time. We also offer a 14 day money back guarantee so you can try our services without any risk.
          </div>
          <div class="col-lg-6 pr-lg-5 pb-5">
            <h6 class="mt-0">Where is Agiler located?</h6>
            Agiler is located in Denver Colorado and San Jose California. All of our staff are based in the United States.
          </div>
          <div class="col-lg-6 pr-lg-5 pb-5">
            <h6 class="mt-0">I have more questions. How do I contact you?</h6>
            We are available during business hours through email at <router-link to="/contact">hello@agiler.io</router-link> or by calling <a href="tel:1-800-249-1897">(800) 249-1897</a>. Emergency support for your store is available through your project dashboard 24 hours a day 365 days a year.
          </div>
        </div>
      </div>
    </section>

    <section class="container-fluid px-3 bg-dark-blue pos-relative d-flex flex-column py-5">
      <div class="container">
        <div class="row flex-1">
          <div id="particles-blog-background" class="pos-0"></div>
          <div class="container d-flex flex-column pb-4">
            <div class="row flex-1 align-items-center">
              <div class="col-lg-12">
                <div class="section-title particles-section-title text-inverse mb-4 pb-1">
                  <h1 class="heading fw-500 fs-46">Featured <span class="text-warning">Blog Post</span></h1>
                  <h6 class="subheading">Actionable ecommerce advice</h6>
                </div>
                <div class="widget-list">
                  <div class="row">
                    <div v-if="featured" class="col-4 d-none d-lg-block">
                      <router-link :to="featured.path">
                        <div class="widget-holder mb-3 mr-2">
                          <div class="widget-bg blog-featured rounded-left">
                            <div class="widget-body h-100">
                              <div class="d-flex h-100">
                                <div class="justify-content-start align-self-center">
                                  <div class="text-muted small">Featured</div>
                                  <h6 class="mt-2 mb-3">{{ featured.attributes.title }}</h6>
                                  <div v-if="featured.attributes.tags" class="small">
                                    <i class="fal fa-tag mr-2 pr-1 text-muted"></i>
                                    <span v-for="(tag) in featured.attributes.tags" :key="tag"><router-link :to="tagLink(tag)" class="no-link"><span class="badge mr-1 small"><span class="full" style="color: #ffffff; background: #1976d2;">{{ tag }}</span></span></router-link></span>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </router-link>
                    </div>
                    <div v-if="featured" class="col-8 d-none d-lg-block">
                      <router-link :to="featured.path">
                        <div class="widget-holder mb-3 rounded-right">
                          <div class="blog-preview-image featured" :style="`background-image: url('${featured.attributes.image}');`"></div>
                        </div>
                      </router-link>
                    </div>
                    <div v-if="featured" class="col-md-12 d-md-block d-lg-none">
                      <router-link :to="featured.path">
                        <div class="widget-holder mb-3">
                          <div class="widget-bg rounded overflow-hidden">
                            <div v-if="featured.attributes.image" class="blog-preview-image featured" :style="`background-image: url('${featured.attributes.image}');`"></div>
                            <div class="widget-body">
                              <div class="text-muted small">Featured</div>
                              <h6 class="mt-2 mb-3">{{ featured.attributes.title }}</h6>
                              <div v-if="featured.attributes.tags" class="small">
                                <i class="fal fa-tag mr-2 pr-1 text-muted"></i>
                                <span v-for="(tag) in featured.attributes.tags" :key="tag"><router-link :to="tagLink(tag)" class="no-link"><span class="badge mr-1 small"><span class="full" style="color: #ffffff; background: #1976d2;">{{ tag }}</span></span></router-link></span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </router-link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <Footer />
  </div>
</template>

<script>
import Footer from '@/components/Footer.vue';
import p from '@/particles.json';

require('particles.js/particles');

export default {
  data() {
    return {
      loginButtonText: 'Log In',
      featured: {
        path: '/blog/font-display-time-to-interactive',
        attributes: {
          featured: 'true',
          title: 'Using font-display to Improve Time-To-Interactive',
          description: 'How to use font-display with custom fonts to display text faster.',
          image: '/contents/2021/01/alexander-andrews-zw07kVDaHPw-unsplash.jpg',
          date: '2021-01-22',
          tags: [
            'performance',
            'ui/ux',
          ],
        },
      },
      year: new Date().getFullYear(),
    };
  },
  methods: {
    tagLink(tag) {
      return `/${this.$route.params.section}/tag/${this.tagPath(tag)}`;
    },
    tagPath(tag) {
      return tag.replace(/[\W_]+/g, '-');
    },
  },
  async mounted() {
    this.$store.commit('ready', true);
    particlesJS('particles-background', p); // eslint-disable-line
    particlesJS('particles-pricing-background', p); // eslint-disable-line
    particlesJS('particles-signup-background', p); // eslint-disable-line
    particlesJS('particles-blog-background', p); // eslint-disable-line
    if (await this.$auth.isAuthenticated()) {
      this.loginButtonText = 'Dashboard';
    }
  },
  beforeDestroy() {
    if (window.pJSDom instanceof Array && window.pJSDom.length > 0) {
      window.pJSDom[0].pJS.fn.vendors.destroypJS();
    }
    window.pJSDom = [];
  },
  components: {
    Footer,
  },
};
</script>
